

window.addEventListener('load', (event) => {
	if (!nocookie) {	
		fetch("../../landings/ix17.01/dist/css/cookies-consent.css", {cache: "no-store"})
		.then((response) => {
			//console.log(response)
			return response.text();
		})
		.then((responseText) => {
			let  modalCookie = document.querySelector(".cmpwrapper");
			const style = document.createElement ('style');
			style.innerHTML = responseText;
			if (modalCookie) {
				document.querySelector(".cmpwrapper").shadowRoot.appendChild (style);
			} else {
				setTimeout(() => {
					modalCookie = document.querySelector(".cmpwrapper");
					if (modalCookie) {
						document.querySelector(".cmpwrapper").shadowRoot.appendChild (style);
					} else {
						setTimeout(() => {
							modalCookie = document.querySelector(".cmpwrapper");
							if (modalCookie) {
								document.querySelector(".cmpwrapper").shadowRoot.appendChild (style);
							} else {
								console.log("Ha tardado demasiado en cargar el modulo de consentmanager");
							}
						}, 450);
					}
				}, 150);
			}
		});
	}
});

